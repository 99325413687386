/* Provide sufficient contrast against white background */

a {
  color: #0366d6;
}

body {
  height: 100vh;
  margin: 0;
  padding: 0;
}

code {
  color: #E01A76;
}

.btn-primary {
  color: #fff;
  background-color: #1b6ec2;
  border-color: #1861ac;
}
